import styled from "styled-components";

export const MainviewContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 0px;
  border-radius: 7px;
  margin: 5px;
  overflow-y: hidden;
  overflow-x: hidden;
`;
